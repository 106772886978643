import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Home from "./pages/home";
import Privacy from "./pages/privacy";
import Contact from "./pages/contact";
import Merchants from "./pages/for-merchants";
import Delivery from "./pages/for-delivery";
import MerchantTC from "./pages/merchant-terms-conditions";
import DeliveryTC from "./pages/delivery-terms-conditions";
import DownloadQR from "./pages/download-app-qr";
import DownloadMeta from "./pages/download-app-meta";
import DownloadWhatsapp from "./pages/download-app-whatsapp";
import DownloadApp from "./pages/download-app";
import TermsConditions from "./pages/terms-conditions";
import PaymentSuccess from "./pages/success_payment";
import PaymentFailed from "./pages/fail_payment";
import Layout from "./components/Layout";
import ReferEarn from "./pages/refer-earn";
import AdminLogin from "./adminPages/adminLogin";
import Dashboard from "./adminPages/dashboard";
import OrderStatus from "./adminPages/orderStatus";
import OnlineMerchants from "./adminPages/onlineMerchants";
import RegisteredMerchants from "./adminPages/registeredMerchants";
import OnlineDeliveryPartners from "./adminPages/onlineDeliveryPartners";
import Medicines from "./adminPages/medicines";
import SupportRequests from "./adminPages/supportRequests";
import CustomerRequestDetails from "./adminPages/customer-support/CustomerRequestDetails";
import UserOrderHistory from "./adminPages/customer-support/UserOrderHistory";
import Categories from "./adminPages/categories";
import SubCategories from "./adminPages/subcategories";
import ProductsList from "./adminPages/productList";
import Otc from "./adminPages/otc";
import SplitSettlementDetails from "./adminPages/splitPayments";
import AddProduct from "./adminPages/addProduct";

const PrivateRoute = ({ children }) => {
  return sessionStorage.getItem("authToken") ? (
    children
  ) : (
    <Navigate to="/adminLogin" />
  );
};

function App() {
  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            <Layout>
              <Home />
            </Layout>
          }
        />
        <Route
          path="/refer-earn"
          element={
            <Layout>
              <ReferEarn />
            </Layout>
          }
        />
        <Route
          path="/privacy"
          element={
            <Layout>
              <Privacy />
            </Layout>
          }
        />
        <Route
          path="/terms-and-conditions"
          element={
            <Layout>
              <TermsConditions />
            </Layout>
          }
        />
        <Route
          path="/contact"
          element={
            <Layout>
              <Contact />
            </Layout>
          }
        />
        <Route
          path="/merchants"
          element={
            <Layout>
              <Merchants />
            </Layout>
          }
        />
        <Route
          path="/delivery"
          element={
            <Layout>
              <Delivery />
            </Layout>
          }
        />
        <Route
          path="/merchant-terms-and-conditions"
          element={
            <Layout>
              <MerchantTC />
            </Layout>
          }
        />
        <Route
          path="/delivery-terms-and-conditions"
          element={
            <Layout>
              <DeliveryTC />
            </Layout>
          }
        />
        <Route
          path="/download"
          element={
            <Layout>
              <DownloadQR />
            </Layout>
          }
        />
        <Route
          path="/download-meta"
          element={
            <Layout>
              <DownloadMeta />
            </Layout>
          }
        />
        <Route
          path="/download-whatsapp"
          element={
            <Layout>
              <DownloadWhatsapp />
            </Layout>
          }
        />
        <Route
          path="/download-app"
          element={
            <Layout>
              <DownloadApp />
            </Layout>
          }
        />

        <Route
          path="/payment-success"
          element={
            <Layout>
              <PaymentSuccess />
            </Layout>
          }
        />
        <Route
          path="/payment-failed"
          element={
            <Layout>
              <PaymentFailed />
            </Layout>
          }
        />
        <Route path="/adminLogin" element={<AdminLogin />} />
        <Route
          path="/admin/dashboard"
          element={
            <PrivateRoute>
              <Dashboard />
            </PrivateRoute>
          }
        />
        <Route path="/admin/order-status" element={<OrderStatus />} />
        <Route path="/admin/add-product" element={<AddProduct />} />
        <Route path="/admin/merchants-online" element={<OnlineMerchants />} />
        <Route
          path="/admin/merchants-registered"
          element={<RegisteredMerchants />}
        />

        <Route
          path="/admin/online-delivery-partners"
          element={<OnlineDeliveryPartners />}
        />
        <Route path="/admin/medicines" element={<Medicines />} />
        <Route path="admin/otc" element={<Otc />} />
        <Route path="/admin/support-requests" element={<SupportRequests />} />
        <Route
          path="/admin/customer-request/:id"
          element={<CustomerRequestDetails />}
        />
        <Route path="/user-order-history/:id" element={<UserOrderHistory />} />
        <Route path="/admin/categories" element={<Categories />} />
        <Route
          path="/admin/subcategories/:categoryId"
          element={<SubCategories />}
        />
        <Route
          path="/subcategory/:subCategoryId/products"
          element={<ProductsList />}
        />
        <Route
          path="/admin/split-settlement-details"
          element={<SplitSettlementDetails />}
        />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </Router>
  );
}

export default App;
